import React from 'react';
import PropTypes from 'prop-types';

// Style
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';
import { COLORS, SURFACE_COLORS, TEXT_COLORS } from 'Style/colors';

// Utils
import replaceTags from 'Utils/i18n/replace-tags';
import { shortFormatLocalized } from 'Utils/date-util';
import { getAuthorUrl } from 'Utils/content/flipboard-urls';
import Attribution from 'Utils/content/attribution';

// Components
import WebLink from '../web-link';
import withT from 'ComponentLibrary/hocs/withT';
import AuthorAvatar from '../attribution/author-avatar';
import Bullet from 'Webapp/shared/app/components/bullet';
import ItemFlipNote from 'Webapp/shared/app/components/item/item-flip-note';

const AVATAR_SIZE = 40;
const BORDER_SIZE = 2;

const Wrapper = styled.div({
  backgroundColor: SURFACE_COLORS.primary,
  padding: `${SPACING.XLARGE} ${SPACING.LARGE} ${SPACING.LARGE}`,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: SPACING.MEDIUM,
  marginTop: SPACING.LARGE,

  '.image-unavailable': {
    backgroundColor: SURFACE_COLORS.secondary,
  },
});

const AttributionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const AttributionAuthorAvatar = styled(AuthorAvatar)({
  border: `${BORDER_SIZE}px solid ${COLORS.staticWhite}`,
  borderRadius: SPACING.BASE6X,
  minWidth: `${AVATAR_SIZE + BORDER_SIZE + BORDER_SIZE}px`,
});

const AttributionParagraph = styled.p(BODY_TYPES.LARGE_STANDARD, {
  color: TEXT_COLORS.secondary,
  wordBreak: 'break-all',
  paddingLeft: SPACING.MEDIUM,
});

const AttributionLink = styled(WebLink)({
  color: TEXT_COLORS.primary,
});

// TODO: Translate "Magazine" label
function FlipTargetMagazine({ item, magazine, t }) {
  const flipper = Attribution.getFlipper(item);
  const { author } = flipper;
  const authorName = author && Attribution.getAuthorName(author);
  if (!authorName || !magazine.title) {
    return null;
  }

  return (
    <Wrapper className="flip-target-magazine">
      <AttributionWrapper>
        {author && (
          <AttributionAuthorAvatar author={author} avatarSize={AVATAR_SIZE} />
        )}
        <AttributionParagraph>
          {author &&
            replaceTags(
              t('profile_flipped_into_magazine', {
                profileName: authorName,
                magazineName: magazine.title,
              }),
              {
                profileLink: function ProfileLink(displayName) {
                  return (
                    <AttributionLink href={getAuthorUrl(author)}>
                      {displayName}
                    </AttributionLink>
                  );
                },
                magazineLink: function MagazineLink(title) {
                  return (
                    <AttributionLink href={magazine.canonicalPath}>
                      {title}
                    </AttributionLink>
                  );
                },
              },
            )}
          {item.dateCreated && (
            <span>
              <Bullet />
              {shortFormatLocalized(item.dateCreated, t)}
            </span>
          )}
        </AttributionParagraph>
      </AttributionWrapper>
      <ItemFlipNote
        item={item}
        isItemCaption
        overrideBackgroundColor={SURFACE_COLORS.primary}
      />
    </Wrapper>
  );
}

FlipTargetMagazine.propTypes = {
  item: PropTypes.object.isRequired,
  magazine: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withT(FlipTargetMagazine);
