import React from 'react';
import PropTypes from 'prop-types';

import { mightBeSameItem } from 'Utils/content/item-util';

// Components
import SectionLink from '../../components/section-link';
import { NavFromContext } from 'ComponentLibrary/context';
import ItemList from '../section/item-list';
import { ITEM_DISPLAY_TYPES } from '../../components/item';
import withT from 'ComponentLibrary/hocs/withT';

import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/section-tag';
// Libs
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';
import sentenceCase from 'Utils/content/sentence-case';
import replaceTags from 'Utils/i18n/replace-tags';

function MoreStories({
  article,
  articleSection,
  headerOverride,
  limit,
  itemFilter,
  t,
  hideSeeMore,
  seeMoreActionOverride,
  condensedItemModifiers,
}) {
  if (!article || !articleSection) {
    return null;
  }

  const notSameItemFilter = (item) => !mightBeSameItem(item, article);
  const filteredItems =
    articleSection.items &&
    articleSection.items.filter(itemFilter).filter(notSameItemFilter);

  const items = filteredItems.slice(0, limit) || [];

  return (
    // NOTE: This gets nested under an <aside> which is a "sectioning" tag,
    // but if this needs to be imported elsewhere, it should be wrapped in another sectioning tag,
    // because of the header tag used here.
    // TODO: Confirm the proper NAV_FROM value
    <NavFromContext.Provider value={USAGE_NAV_FROMS.SEE_MORE}>
      <div className="more-stories">
        <h2
          className="more-stories__title ui-subheading--large-standard"
          data-test-id="more-stories"
        >
          {headerOverride ? (
            headerOverride
          ) : (
            <React.Fragment>
              {replaceTags(
                t('more_stories_from', {
                  sectionName: articleSection.title,
                }),
                {
                  sectionLink: function SectionLinkWrapper(title) {
                    return articleSection.isTopic ? (
                      <SectionTag
                        section={articleSection}
                        styleVariation={
                          TOPIC_TAG_STYLE_VARIATIONS.CAPITALIZE_COLOR
                        }
                      />
                    ) : (
                      <SectionLink
                        className="more-stories__see-more"
                        item={article}
                        section={articleSection}
                      >
                        {title}
                      </SectionLink>
                    );
                  },
                },
              )}
            </React.Fragment>
          )}
        </h2>
        <ItemList
          items={items}
          section={articleSection}
          displayType={ITEM_DISPLAY_TYPES.CONDENSED}
          truncateTitleLength={65}
          limit={limit}
          condensedItemModifiers={condensedItemModifiers}
        />
        {!hideSeeMore && (
          <div className="more-stories__footer">
            {seeMoreActionOverride || (
              <SectionLink
                className="more-stories__see-more ui-text--supporting-emphasis"
                item={article}
                section={articleSection}
              >
                {sentenceCase(t('see_more'))}
              </SectionLink>
            )}
          </div>
        )}
      </div>
    </NavFromContext.Provider>
  );
}

MoreStories.propTypes = {
  article: PropTypes.object.isRequired,
  articleSection: PropTypes.object.isRequired,
  headerOverride: PropTypes.node,
  displayHorizontal: PropTypes.bool,
  limit: PropTypes.number,
  className: PropTypes.string,
  itemFilter: PropTypes.func,
  t: PropTypes.func.isRequired,
  hideSeeMore: PropTypes.bool,
  seeMoreActionOverride: PropTypes.object,
  condensedItemModifiers: PropTypes.arrayOf(PropTypes.string),
};

MoreStories.defaultProps = {
  headerOverride: null,
  displayHorizontal: false,
  limit: 10,
  className: '',
  itemFilter: (i) => i.isArticle || i.isStoryboard || i.isFlipComposeImage,
  hideSeeMore: false,
  seeMoreActionOverride: null,
  condensedItemModifiers: [],
};

export default withT(MoreStories);
