import FlapUtil from 'Utils/content/flap-util';
import sentenceCase from 'Utils/content/sentence-case';
import { getRelatedSectionsByIdKey } from 'Utils/content/item-util';
import { RelatedSectionType, ArticleContentSectionType } from 'Webapp/enums';

/* Ordered list (by priority) of our related content sections on the article page.*/
const CONTENT_SECTION_ORDER = [
  ArticleContentSectionType.SIDEBAR,
  ArticleContentSectionType.CONDENSED_1,
  ArticleContentSectionType.ITEM_CARD_1,
  ArticleContentSectionType.ITEM_CARD_2,
];
const CONTENT_SECTION_ORDER_SMALL = [
  ArticleContentSectionType.CONDENSED_0,
  ArticleContentSectionType.CONDENSED_1,
  ArticleContentSectionType.ITEM_CARD_1,
  ArticleContentSectionType.ITEM_CARD_2,
];

const RELATED_SECTION_DISPLAY_META = {
  [RelatedSectionType.STORYBOARDS]: {
    type: RelatedSectionType.STORYBOARDS,
    hideSeeMore: true,
  },
  [RelatedSectionType.ARTICLES]: {
    type: RelatedSectionType.ARTICLES,
    hideSeeMore: true,
  },
  [RelatedSectionType.TOPICS]: {
    type: RelatedSectionType.TOPICS,
  },
  [RelatedSectionType.AUTHOR]: {
    type: RelatedSectionType.AUTHOR,
  },
  [RelatedSectionType.FLIPBOARD_TV]: {
    type: RelatedSectionType.FLIPBOARD_TV,
  },
};

/**
 * Given a content_section_key, returns the min/max Item limits required for the section.
 * @param {*} content_section_key
 * @param {*} isDesktop
 * @returns
 */
const getItemLimits = (content_section_key, isDesktop) => {
  switch (content_section_key) {
    case ArticleContentSectionType.SIDEBAR:
      return { minItems: 3, maxItems: 4 };
    case ArticleContentSectionType.CONDENSED_0:
      return {
        truncateItemTitleLength: isDesktop ? 180 : 40,
        minItems: isDesktop ? 4 : 2,
        maxItems: isDesktop ? 8 : 4,
      };
    case ArticleContentSectionType.CONDENSED_1:
      return {
        truncateItemTitleLength: isDesktop ? 180 : 40,
        minItems: isDesktop ? 4 : 2,
        maxItems: isDesktop ? 8 : 4,
      };
    case ArticleContentSectionType.ITEM_CARD_1:
      return { minItems: isDesktop ? 3 : 2, maxItems: isDesktop ? 3 : 2 };
    case ArticleContentSectionType.ITEM_CARD_2:
      return { minItems: isDesktop ? 3 : 2, maxItems: isDesktop ? 3 : 2 };
  }
};

const ARTICLE_RELATED_SECTION_ORDER = [
  RelatedSectionType.STORYBOARDS,
  RelatedSectionType.ARTICLES,
  RelatedSectionType.TOPICS,
];

const FIRST_PARTY_VIDEO_RELATED_SECTION_ORDER = [
  RelatedSectionType.ARTICLES,
  RelatedSectionType.AUTHOR,
  RelatedSectionType.TOPICS,
];

const THIRD_PARTY_VIDEO_RELATED_SECTION_ORDER = [
  RelatedSectionType.ARTICLES,
  RelatedSectionType.TOPICS,
  RelatedSectionType.FLIPBOARD_TV,
];

const POST_RELATED_SECTION_ORDER = [
  RelatedSectionType.STORYBOARDS,
  RelatedSectionType.ARTICLES,
  RelatedSectionType.TOPICS,
  RelatedSectionType.AUTHOR,
];

/**
 * Given a current article, generates a priority ordered list of related sections. The returned objects
 * are wrapped to include display relevant metadata. For now, it will also include a key of the
 * related section type, as the First and Third party Video pages are NOT ordered by priority.
 * @param {Object}} currentArticle - Current Article object.
 * @param {Array} entries - List of section entries
 * @returns
 */
const getOrderedRelatedSectionsForArticle = (currentArticle, entries) => {
  if (!currentArticle) {
    return [];
  }
  const relatedSections = [];
  const { isFirstPartyVideo, isStandalonePost } = currentArticle;
  const isThirdPartyVideo = !isFirstPartyVideo && currentArticle.isVideo;

  //   article && !article.isFirstPartyVideo && article.isVideo;

  const getRelatedSections = (sectionIdKey, numSections) =>
    getRelatedSectionsByIdKey(currentArticle, entries, sectionIdKey)
      ?.map((section) => ({
        section,
        ...RELATED_SECTION_DISPLAY_META[sectionIdKey],
      }))
      .filter((relatedSection) => relatedSection.section?.items)
      .slice(0, numSections) || [];

  // Include Article section for all article types
  const articleSection =
    currentArticle.articleSectionId &&
    FlapUtil.getSectionByRemoteId(currentArticle.articleSectionId, entries);

  const showArticleSection =
    articleSection && !articleSection.isProfile && articleSection.items;
  if (showArticleSection) {
    relatedSections.push({
      section: articleSection,
    });
  }

  // Add the other related section types in order
  let relatedSectionOrder = ARTICLE_RELATED_SECTION_ORDER;
  if (isFirstPartyVideo) {
    relatedSectionOrder = FIRST_PARTY_VIDEO_RELATED_SECTION_ORDER;
  } else if (isThirdPartyVideo) {
    relatedSectionOrder = THIRD_PARTY_VIDEO_RELATED_SECTION_ORDER;
  } else if (isStandalonePost) {
    relatedSectionOrder = POST_RELATED_SECTION_ORDER;
  }
  relatedSectionOrder.forEach((key) =>
    relatedSections.push(
      ...getRelatedSections(key, key === RelatedSectionType.TOPICS ? 3 : 1),
    ),
  );

  return relatedSections;
};

/**
 * Searches the priority ordered list of related Sections for the next section with a
 * minimum numItems. Anything NOT passing is discarded.
 */
const grabNextSection = (relatedSections, numItems) => {
  if (!relatedSections || relatedSections.length < 1) {
    return null;
  }
  const checked = [];
  let nextSection = relatedSections.shift();

  while (
    !nextSection ||
    !nextSection.section ||
    !nextSection.section.items ||
    nextSection.section.items.length < numItems
  ) {
    checked.push(nextSection.section.title);
    if (relatedSections.length < 1) {
      return null;
    }
    nextSection = relatedSections.shift();
  }
  return nextSection;
};

/**
 * Given a list or priority ordered list of relatedSections, matches them to content section keys
 * to be rendered on the article page.
 * @param {*} relatedSections
 * @param {*} isDesktop
 * @returns
 */
const determineContentDisplaySections = (relatedSections, isDesktop, t) => {
  const contentSections = {};
  const contentSectionOrder = isDesktop
    ? CONTENT_SECTION_ORDER
    : CONTENT_SECTION_ORDER_SMALL;

  contentSectionOrder
    .filter((key) => !(!isDesktop && key === ArticleContentSectionType.SIDEBAR))
    .forEach((key) => {
      const currItemLimits = getItemLimits(key, isDesktop);
      const nextSection = grabNextSection(
        relatedSections,
        currItemLimits.minItems,
      );
      if (nextSection) {
        let headerOverride = null;
        if (nextSection.type === RelatedSectionType.STORYBOARDS) {
          headerOverride = sentenceCase(t('related_storyboards'));
        } else if (nextSection.type === RelatedSectionType.ARTICLES) {
          headerOverride = sentenceCase(t('related_articles'));
        }
        contentSections[key] = {
          ...currItemLimits,
          ...nextSection,
          headerOverride,
        };
      }
    });

  return contentSections;
};

/**
 * Given a related section type, return the section(s) from the relatedSections array.
 * TODO: This is a temporary solution as the main article view, displays them in order.
 * This supports the First and Third Party video views that look for specific content at various
 * parts of the view.
 * @param {Array} relatedSections - Ordered Array of related sections generated by getOrderedRelatedSectionsForArticle()
 * @param {String} type of the related section as keyed by RelatedSectionType
 * @param {*} getFirst - Optionally returns all matching, otherwise it just returns the first one found.
 * @returns
 */
const getRelatedSectionByType = (relatedSections, type, getFirst = true) => {
  if (getFirst) {
    return relatedSections.find((section) => section.type === type)?.section;
  }
  return relatedSections
    .filter((section) => section.type === type)
    .map((relatedSection) => relatedSection.section);
};

export default {
  getOrderedRelatedSectionsForArticle,
  determineContentDisplaySections,
  getRelatedSectionByType,
};
