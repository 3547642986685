import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { SPACING } from 'Style/spacing';

// Components
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from '../section-tag';

const StyledTopicTagsListItem = styled.li({
  display: 'inline-block',
  marginRight: SPACING.BASE,

  '.topic-tag': {
    paddingLeft: '0',
  },
});

function TopicTags({ topics, limit }) {
  const displayTopics = (topics || []).slice(0, limit);
  return (
    <ul className="topic-tags">
      {displayTopics.map((topic) => (
        <StyledTopicTagsListItem key={topic.remoteid}>
          <SectionTag
            section={topic}
            styleVariation={TOPIC_TAG_STYLE_VARIATIONS.TEXT}
          />
        </StyledTopicTagsListItem>
      ))}
    </ul>
  );
}

TopicTags.propTypes = {
  topics: PropTypes.array,
  limit: PropTypes.number,
};

TopicTags.defaultProps = {
  topics: [],
  limit: 5,
};

export default TopicTags;
